import React from 'react'
import { graphql } from "gatsby"
import { Container } from 'react-grid-system'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import Outro from '../components/rows/outro'
import ImgSection from '../components/rows/imgsection'
import DataAccordion from '../components/elements/accordion'

import Banner from '../images/img/kiln-gallery/kiln-1.jpg'

import Slide1 from '../images/img/kiln-gallery/kiln-2.jpg'
import Slide2 from '../images/img/kiln-gallery/kiln-3.jpg'
import Slide3 from '../images/img/kiln-gallery/kiln-4.jpg'
import Slide4 from '../images/img/kiln-gallery/kiln-5.jpg'
import Slide5 from '../images/img/kiln-gallery/kiln-6.jpg'
import Slide6 from '../images/img/kiln-gallery/kiln-7.jpg'

const slideShow = [
  {
    image: Slide1,
  },
  {
    image: Slide2,
  },
  {
    image: Slide3,
  },
  {
    image: Slide4,
  },
  {
    image: Slide5,
  },
  {
    image: Slide6,
  }
]

export default function OurKilns({data}) { // <== Make sure data is sent to the function

    return (
        <Layout>
        <Seo title="How Our Kilns Work" />
        <Hero
            video
        />
        {data.allStrapiHowOurKilnsWork.edges.map(({ node }) => ( // <== Map out the data from the node.
          <>
              <div className="intro py-56">
                <Intro key={node.ourkilns_intro.id}
                  title={node.ourkilns_intro.title}
                  subtitle={node.ourkilns_intro.subtitle}
                />
                <DataAccordion 
                  data={node.ourkilns_accordion}
                />
              </div>
                <ImgSection key={node.ourkilns_img.id}
                    buttonwhite
                    subtitle={node.ourkilns_img.subtitle}
                    title={node.ourkilns_img.title}
                    description={node.ourkilns_img.description}      
                    slideshow={slideShow}
                    link={node.ourkilns_img.button_title}
                    href={node.ourkilns_img.button_link}
                />                
                <Outro key={node.ourkilns_outro.id}
                    subtitle={node.ourkilns_outro.subtitle}
                    title={node.ourkilns_outro.title}
                    link={node.ourkilns_outro.button_title}
                    href={node.ourkilns_outro.button_link}
                />
            </>
        ))}            
        </Layout>    
    )
}


export const query = graphql`
query OurKilns {
    allStrapiHowOurKilnsWork {
      edges {
        node {
          ourkilns_intro {
            id
            subtitle
            title
          }
          ourkilns_accordion {
            id
            title
            description
          }
          ourkilns_img {
            id
            subtitle
            title
            description
            button_title
            button_link
          }
          ourkilns_outro {
            id
            subtitle
            title
            button_title
            button_link
          }
        }
      }
    }
  }
`